<template>
	<div>
		<div style="width: 1520px; margin: auto; margin-bottom: 10px; font-size: 12px" v-if="msg === '一件起订'">
			SKU:<el-input size="mini" v-model="sku" style="width: 180px"></el-input>
		</div>

    <Footer @setPages="setPages" msg="right" ref="footPagesR"></Footer>

		<div class="goodsList" ref="goodslist" >
			<!-- 普通商品start -->
			<template v-show="presellListData.length>0">
			<!-- 预售商品start -->
				<div class="pcenter_gds" v-for="(item,index) in presellListData">
					<div class="pcenter_gds_img">
						<img @click="toGoUrl(item.item_no)" width="100%" height="356" :src="`${item.img}`"  alt=""/>
						<div class="pcenter_gds_overDate">{{ $t("End date") }}：{{ item.deadline|formatDateStr }}</div>
						<div class="pcenter_gds_credenttial">
						<img v-for="(val,key) in item.collect_img_arr" :key="key" :src="val" height="40" alt="">
						</div>
					</div>
					<div class="pcenter_gds_info">
						<!-- 进度条 -->
						<el-progress class="pcenter_percentage_bar" :text-inside="true" :stroke-width="20" :percentage="item.percentage" color="#238da5"></el-progress>
						<p style="text-align: center;">
						<span style="color: #000000; font-size: 14px; margin-right: 5px;">
										{{ item.item_no }}
									</span>
						<i class="el-icon-document-copy" v-clipboard:copy="item.item_no" v-clipboard:success="onCopy" v-clipboard:error="onError"></i>
						</p>
						<el-tooltip  class="item" effect="light" :content="item.showName" placement="top">
						<h3 class="pcenter_gds_info_h3" @click="toGoUrl(item.item_no)">
						{{ item.showName | cutstr }}
						</h3>
						</el-tooltip>

						<p class="pcenter_gds_info_pe">
						{{ $t("Wholesale price") }}：<span style="color: red;">￥{{ item.base_price.toFixed(2) }}</span>
						<span style="margin-left: 10px;"></span>
						{{ $t("RETAIL PRICE") }}：<span style="color: red;">￥{{ item.sale_price.toFixed(2) }}</span>
						</p>
						<p class="pcenter_gds_info_num">
						<el-input-number size="small" v-model="item.buy_num" :min="item.purchase_spec" :max="999"
										:step="item.purchase_spec">
						</el-input-number>
						</p>

						<p class="pcenter_gds_info_act" v-if="item.sku_status === 0">
						<a @click="addOrder(item)">{{ $t("Place an order") }} [{{item.orderCount}}]</a>
						</p>

					</div>
				</div>
			<!-- 预售商品end -->
			</template>
			<template v-show="goodsList.length>0">
				<div class="goodsList_div" v-for="(item, index) in goodsList" :key="index">
					<div class="goodsList_div_imgdiv">
						<div class="goodsList_div_imgdiv_icon">
							<img v-for="(fname, index) in item.iconArr" height="30" :src="fname" :key="index"  alt=""/>
						</div>
						<div class="goodsList_div_imgdiv_goodsImg">
							<img :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
								style="width: 350px" @click="clickImg(item)"  alt=""/>
						</div>
						<div class="goodsList_div_imgdiv_msg" v-if="msg === '新品专区'">
							<div class="marker"></div>
							<p>{{ item.new_time | formatDateStr }}</p>
						</div>
						<div @click="clickCollect(item)" class="goodsList_div_imgdiv_collect">
							<span v-if="item.collect_info"><img height="30" :src="hasCollect.yes"  alt=""/></span>
							<span v-else><img height="30" :src="hasCollect.not"  alt=""/></span>
						</div>
					</div>
					<div class="goodsList_div_bottom">
						<div class="prod_title">
							<span style="color: #000000; font-size: 14px; margin-right: 5px">
								{{ item.item_no }}
							</span>
							<i class="el-icon-document-copy" v-clipboard:copy="item.item_no" v-clipboard:success="onCopy"
								v-clipboard:error="onError"></i>
						</div>
						<div class="prod_title">
							<el-tooltip :content="item.item_en" placement="bottom" effect="light">
								<p style="color: #000000; font-size: 15px; margin-right: 5px">
									{{ item.GoodsName | cutstr }}
								</p>
							</el-tooltip>
						</div>
						<div class="prod_title" v-if="item.did_good==null">
							<div style="font-size: 14px; color: #000000">
								{{ $t("Wholesale price") }}:
							</div>
							<div style="font-size: 14px; color: #d92524; margin-left: 5px">
								{{ $t("Price symbol") }}{{ item.base_price.toFixed(2) }}
							</div>
							<div style="font-size: 14px; color: #000000; margin-left: 5px">
								{{ $t("RETALL PRICE") }}:
							</div>
							<div style="font-size: 14px; color: #d92524; margin-left: 5px">
								{{ $t("Price symbol") }}{{ item.sale_price.toFixed(2) }}
							</div>
						</div>

						<!-- 折扣区域展示 begin -->
						<div class="prod_title" v-if="item.did_good!=null">
							<div style="font-size: 14px; color: #000000;text-decoration:line-through">
								{{ $t("Wholesale price") }}:
							</div>
							<div style="font-size: 14px; color: #d92524; margin-left: 5px;text-decoration:line-through">
								{{$t('Price symbol')}}{{ item.base_price.toFixed(2) }}
							</div>
							<div style="font-size: 14px; color: #000000; margin-left: 5px">
								{{ $t("RETAIL PRICE") }}:
							</div>
							<div style="font-size: 14px; color: #d92524; margin-left: 5px">
								{{$t('Price symbol')}}{{ item.sale_price.toFixed(2) }}
							</div>
						</div>
						<div class="prod_title" v-if="item.did_good!=null">
							<div style="font-size: 14px; color: #000000;">
								{{ $t("Discount Price") }}:
							</div>
							<div style="font-size: 14px; color: #d92524; margin-left: 5px;">
								{{$t('Price symbol')}}{{ item.did_good.dis_price.toFixed(2) }}
							</div>
						</div>
						<!-- 折扣区域展示 end -->

						<div class="change_num">
							<el-input-number v-model="item.addNum" @change="handleChange" :step="item.purchase_spec"
								:min="item.purchase_spec" :max="item.us_num" v-if="item.is_moq === 0">
							</el-input-number>
				<el-input-number v-model="item.addNum" @change="handleChange" :step="item.purchase_spec"
								:min="item.purchase_spec" :max="item.us_num" v-else>
							</el-input-number>
						</div>


						<div class="prod_handle">
							<div class="prod_handle_add">
								<p class="prod_handle_title" v-if="item.us_num > 0 && item.display_flag == 1">
									<a @click="addCarts(item)">{{ $t("Add to Cart") }}</a>
									<a v-if="item.user_cart_info"
										style="margin-left: 8px">[{{ item.user_cart_info.num }}]</a>
									<a v-else style="margin-left: 8px">[0]</a>
									<i class="el-icon-delete" style="margin-left: 8px"
										@click="delteCartGoods(item.item_no)"></i>
								</p>
								<p class="prod_handle_not" v-else>
									{{ $t("coming soon") }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</template>

			<!-- 普通商品end -->

		</div>

		<div class="notFindGoods" v-show="goodsList.length == 0">
			{{ $t("No products of this type found") }}
		</div>

    <Footer @setPages="setPages" ref="footPagesB"></Footer>
	</div>
</template>
<script>
import { GetGoodsList } from "@/api/goods/goods.js";
import common from "@/common/mixins/common.js";
import { AddToCartByGoods } from "@/api/carts/carts.js";
import Footer from "@/views/web/allGoods/footer/index.vue";
import { DeleteGoods2Carts } from "@/api/carts/carts";
import { addColect, delCollect } from "@/api/goods/collect";
import { getPresellData } from "@/api/goods/presell.js";

export default {
		mixins: [common],
		components: {
			Footer,
		},
		props: {
			msg: {
				type: String,
				default: "中国仓产品",
			},
		},
		data() {
			return {
				num: [],
				goodsList: [],
				presellListData:[],//预售商品
				typeList: [],
				sku: "",
				queryData: {
					GtClsNo: "",
					priceBegin: "",
					priceEnd: "",
					page: 1,
					pageSize: 16,
				},
				hasCollect:{
					yes:require("@/assets/images/collect/2.png"),
					not:require("@/assets/images/collect/1.png")
				}
			};
		},
		watch: {
			$route(to, from) {
				//this.$router.go(0);
				this.presellListData = []
				this.goodsList = []
				this.getData();
			},
			sku() {
				this.getData();
			},
		},
		created() {
			this.getData();
		},
		methods: {
			//点击收藏
			clickCollect(row) {
				let postData = {
					"sku": row.item_no,
				}
				if (row.collect_info){
					delCollect(row.item_no).then((res)=>{
						row.collect_info = false
						this.$message.success(this.$t('Cancel collection'))
					})
				} else {

					addColect(postData).then((res) => {
						this.$message.success(this.$t('Successfully joined the collection'))
						this.isJoin = true
						row.collect_info = true
					}).catch((err) => {
						let msg = "";
						if (err.response.data.message == "已经加入收藏了") {
							msg = this.$t('Has been added to the collection')
						}
						this.$message.error(msg)
					})
				}

			},
			//复制商品条码
			onCopy: function() {
				this.$message.success("Product barcode copied to clipboard");
			},
			onError: function() {
				this.$message.success("Copy failed");
			},
			//删除购物车处理
			delteCartGoods(sku) {
				DeleteGoods2Carts(sku).then(() => {
					this.$message.success("successfully deleted");
					this.getData();
					this.$emit("initCart");
				});
			},
			//设置价格 begin
			selPrice(val) {
				//this.$message.success("我是中国产品");
				switch (val.key) {
					case "all":
						this.queryData.priceBegin = "";
						this.queryData.priceEnd = "";
						this.getData();
						break;
					case "1":
						this.queryData.priceBegin = "0";
						this.queryData.priceEnd = "10";
						this.getData();
						break;
					case "2":
						this.queryData.priceBegin = "11";
						this.queryData.priceEnd = "20";
						this.getData();
						break;
					case "3":
						this.queryData.priceBegin = "21";
						this.queryData.priceEnd = "40";
						this.getData();
						break;
					case "4":
						this.queryData.priceBegin = "41";
						this.queryData.priceEnd = "60";
						this.getData();
						break;
					case "5":
						this.queryData.priceBegin = "60";
						this.queryData.priceEnd = "1000";
						this.getData();
						break;
				}
			},
			//设定类型
			setType(id) {
				this.queryData.GtClsNo = id;
				this.queryData.page = 1;
				this.getData();
			},
			//加入购物车
			addCarts(row) {
				if (row.addNum % row.purchase_spec != 0) {
					this.$message.error(
						this.$t("Please change in multiples of specifications")
					);
					this.$nextTick(() => {
						row.addNum = row.purchase_spec;
					});
				} else {
					//判断是否上架
					if (row.display_flag == 1) {
						AddToCartByGoods(row.item_no, row.addNum, row.purchase_spec).then(
							() => {
								this.getData();
								this.$emit("initCart");
							}
						);
					} else {
						this.$message.error(this.$t("Product has been discontinued"));
					}
				}
			},
			//获取列表产品
			getData() {
				let pageType = this.msg;
        switch (pageType) {
					case "中国仓产品":
						this.chinaProducts();
						break;
					case "新品专区":
						this.newArrival();
						break;
					case "全部商品":
						this.allGoods();
						break;
					case "一件起订":
						this.onePiece();
						break;
				}
			},
			//设置翻页
			setPages(page) {
				this.queryData.page = page;
				this.getData();
        this.$nextTick(() => {
          document.body.scroll(0,410)
        })
			},
			//一件起订
			onePiece() {
				//请求参数设置 begin
				let queryData = {};
				if (this.queryData.GtClsNo) {
					if (this.queryData.GtClsNo == "all") {
						queryData.GtClsNo = "";
					} else {
						queryData.GtClsNo = this.queryData.GtClsNo;
					}
				}
				if (this.sku != "") {
					queryData.ItemNo = this.sku;
				}
				queryData.page = this.queryData.page;
				queryData.pageSize = this.queryData.pageSize;
				queryData.Display = "y";
				//queryData.Sw = "12";
				queryData.moq = 1; //查询一件起订
				if (this.queryData.priceBegin && this.queryData.priceEnd) {
					queryData.priceBegin = this.queryData.priceBegin;
					queryData.priceEnd = this.queryData.priceEnd;
				}

				//请求参数设置 end
				GetGoodsList(queryData).then((res) => {
					let resApiData = res.data.data;
					if (resApiData.data) {
            this.goodsList = this.filterGoods(res.data.data.data);
						this.setFootPageShow(resApiData);
					} else {
						this.goodsList = [];
						this.setFootPageShowInit();
					}
				});
			},
			//获取到预售产品
			getPresellData(urlQueryName) {
				let queryData = {
					page: this.queryData.page,
					page_size: this.queryData.pageSize,
					"of_type": 0,
					name_sku:urlQueryName,
				};
				getPresellData(queryData).then((res) => {
					let repData = res.data.data;
					this.presellListData = this.filterData(repData.data);
					var setValue ={
						page:repData.page,
						total:repData.total,
						page_size:repData.page_size,
					}
					this.setFootPageShow(setValue);
					
				});
			},
			//跳转url预售商品
			toGoUrl(sku) {
				//console.log(sku)
				let {
					href,
				} = this.$router.resolve({
					path: "/web/presellInfo",
					query: {
					id: sku.trim(),
					},
				});
				//console.log(href)
				window.open(href);
			},
			//过滤数据
			filterData(datas) {
				for (let key in datas) {
					/* 计算进度条 */
					datas[key].percentage = 0
					if(datas[key].order_num!=0){
					let percentage = (datas[key].order_num/datas[key].target)*100
					if(percentage >= 100){
						datas[key].percentage = 100
					}else{
						datas[key].percentage = Math.floor(percentage) //向下取整
					}
					}
					// 每月10号待产品确认能下单后 进度条都变成100%
					if(datas[key].sku_status == 1){
						datas[key].percentage = 100
					}
					datas[key]["buy_num"] = datas[key]["purchase_spec"];
					if (this.$i18n.locale == "zh-CN") {
					datas[key].showName = datas[key].item_name;
					} else {
					datas[key].showName = datas[key].item_en;
					}
					if (datas[key]["use_yum"] == 1) {
					datas[key]["img"] = datas[key]["yum_base_img"];
					}
					if (datas[key]["collect"] != "") {
					datas[key]["collect_img_arr"] = datas[key]["collect_img"].split(",");
					}
				}
				return datas;
			},
			//全部商品
			allGoods() {
				let urlQuery = this.$route.query;
				//请求参数设置 begin
				let queryData = {};
				queryData.RandomSort = 1 //设置每天随机排序	author@connor
				if (this.queryData.GtClsNo) {
					if (this.queryData.GtClsNo === "all") {
						queryData.GtClsNo = "";
					} else {
						queryData.GtClsNo = this.queryData.GtClsNo;
					}
				}
				queryData.page = this.queryData.page;
				queryData.pageSize = this.queryData.pageSize;
				queryData.Display = "y";
        queryData.noMoq = 1
				if (this.queryData.priceBegin && this.queryData.priceEnd) {
					queryData.priceBegin = this.queryData.priceBegin;
					queryData.priceEnd = this.queryData.priceEnd;
				}
				//queryData.HasShow = 1;
				if (urlQuery.name) {
					if (isNaN(urlQuery.name)) {
						//字符查询
						queryData.GtClsNo = "";
						queryData.findName = urlQuery.name;
						//queryData.HasShow = 0;
						queryData.Display = "y";
            queryData.noMoq = 0
					} else {
						//数字查询
						queryData.GtClsNo = "";
						queryData.ItemNo = urlQuery.name;
						queryData.HasShow = 0;
						queryData.Display = "";
            queryData.noMoq = 0
					}
					// 查询预售商品是否存在
					this.getPresellData(urlQuery.name)
				}
				setTimeout(() => {
					// 如果预售商品不为空，则只显示搜索出来的预售商品
					if(this.presellListData.length===0){
						//请求参数设置 end
						GetGoodsList(queryData).then((res) => {
							let resApiData = res.data.data;
							if (resApiData.data) {
							this.goodsList = this.filterGoods(res.data.data.data);
									this.setFootPageShow(resApiData);
								} else {
									this.goodsList = [];
									this.setFootPageShowInit();
								}
							});
					}
				}, 100);

				
				
			},
			//================ 分页处理 begin
			//分页显示
			setFootPageShow(setValue) {
				this.$refs.footPagesB.setPages(
					setValue.page,
					setValue.total,
					setValue.page_size
				);
				this.$refs.footPagesR.setPages(
					setValue.page,
					setValue.total,
					setValue.page_size
				);
			},
			//初始化分页显示状况
			setFootPageShowInit() {
				this.$refs.footPages.setPages(1, 0, 1);
			},
			//================ 分页处理 end

			//新品专区 new arrivals
			newArrival() {
				//请求参数设置 begin
				let queryData = {};
				if (this.queryData.GtClsNo) {
					if (this.queryData.GtClsNo == "all") {
						queryData.GtClsNo = "";
					} else {
						queryData.GtClsNo = this.queryData.GtClsNo;
					}
				}
				queryData.page = this.queryData.page;
				queryData.pageSize = this.queryData.pageSize;
				queryData.Display = "y";
				queryData.isNew = "yes";
				queryData.orderBy = 1; //按入库时间倒叙
				if (this.queryData.priceBegin && this.queryData.priceEnd) {
					queryData.priceBegin = this.queryData.priceBegin;
					queryData.priceEnd = this.queryData.priceEnd;
				}
				//请求参数设置 end
				GetGoodsList(queryData).then((res) => {
					let resApiData = res.data.data;
					if (resApiData.data) {
            this.goodsList = this.filterGoods(res.data.data.data);
						this.setFootPageShow(resApiData);
					} else {
						this.goodsList = [];
						this.setFootPageShowInit();
					}
				});
			},
			//中国仓产品
			chinaProducts() {
				//请求参数设置 begin
				let queryData = {};
				if (this.queryData.GtClsNo) {
					if (this.queryData.GtClsNo == "all") {
						queryData.GtClsNo = "";
					} else {
						queryData.GtClsNo = this.queryData.GtClsNo;
					}
				}
				queryData.page = this.queryData.page;
				queryData.pageSize = this.queryData.pageSize;
				queryData.Display = "y";
				queryData.InLang = "cn";
				if (this.queryData.priceBegin && this.queryData.priceEnd) {
					queryData.priceBegin = this.queryData.priceBegin;
					queryData.priceEnd = this.queryData.priceEnd;
				}
				//请求参数设置 end

				GetGoodsList(queryData).then((res) => {
					let resApiData = res.data.data;
					if (resApiData.data) {
            this.goodsList = this.filterGoods(res.data.data.data);
						this.setFootPageShow(resApiData);
					} else {
						this.goodsList = [];
						this.setFootPageShowInit();
					}
				});
			},
			//设定一个添加购物车的数据，用来绑定添加购物车的数量
			filterGoods(goodsData) {
				let resData = goodsData;
				for (let i = 0; i < resData.length; i++) {
					resData[i].iconArr = resData[i].file_type_icon_list.split(",");

					//判断添加所属
					if (resData[i].stock_num >= resData[i].purchase_spec) {
						resData[i].addNum = resData[i].purchase_spec;
					} else {
						resData[i].addNum = resData[i].us_num;
						resData[i].purchase_spec = 1;
					}
					//语言判断
					if (this.$i18n.locale == "zh-CN") {
						resData[i].GoodsName = resData[i].item_name;
					} else {
						resData[i].GoodsName = resData[i].item_en;
					}
				}
				return resData;
			},
			handleChange(value) {},
			//跳转到详情页
			clickImg(row) {
				let {
					href
				} = this.$router.resolve({
					path: "/web/goodsDetail",
					query: {
						id: row.item_no,
					},
				});
				window.open(href)
			},
		},
	};
</script>
<style lang="scss" scoped>
	.goodsList {
		width: 1496px;
		margin: 0 auto;
		background-color: #f5f8fa;
		display: grid;
		grid-template-columns: 350px 350px 350px 350px;
		grid-row-gap: 32px;
		grid-column-gap: 32px;

		&_div {
			// background: yellow;
			border: 1px solid #ccc;

			&_imgdiv {
				min-height: 350px;
				position: relative;

				//图标
				&_icon {
					position: absolute;
					top: 2px;
					right: 2px;

					img {
						border: 1px solid #ccc;
						margin-left: 3px;
					}
				}

				&_msg {
					width: 180px;
					height: 20px;
					background-color: #f5f8fa;
					position: relative;
					margin: -30px 0 0 160px;
					display: flex;
					align-items: center;
					justify-content: center;

					p {
						text-align: center;
						margin-left: 5px;
					}
				}

				//商品图标
				&_goodsImg {
					cursor: pointer;
					text-align: center;
					border-bottom: 1px solid #ccc;
					min-height: 350px;
				}
				//收藏
				&_collect{
					position: absolute;
					top: 2px;
					left: 2px;
				}
			}

			//文字按钮样式
			&_bottom {
				text-align: center;
				margin-top: 5px;
			}
		}
	}

	.prod_title {
		width: 350px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.change_num {
		//width: 356px;
		height: 37px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.prod_handle {
		//width: 356px;
		height: 40px;
		background-color: #ddedf0;
		margin-top: 5px;

		&_add {
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
		}

		&_title {
			color: #218da0;
			font-size: 16px;
			border-bottom: 1px solid #218da0;
		}

		&_not {
			color: red;
			font-size: 16px;
		}
	}

	.notFindGoods {
		width: 90%;
		margin: 0 auto;
		text-align: center;
		padding: 20px;
	}

	.marker {
		width: 10px;

		height: 10px;

		border: 1px solid #218da0;

		border-radius: 100px;

		background-color: #218da0;

		opacity: 0.5;
	}

	// 预售商品样式
	//商品中间
.pcenter {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &_gds {
    width: 356px;
    border: 1px solid #ccc;
    margin-bottom: 20px;


    &_info {
      // height: 160px;
      height: 205px;

      // background: yellow;
      &_h3 {
        // background: red;
        padding: 8px;
        text-align: center;
      }

      &_pe {
        // background: skyblue;
        text-align: center;
        padding: 8px;
        font-size: 14px;
      }

      &_num {
        // background: #00979D;
        text-align: center;
        padding: 5px;
      }

      &_act {
        text-align: center;
        height: 45px;
        background: #ddedf0;
        line-height: 45px;
        color: #00979D;
      }
    }

    &_img {
      position: relative;
    }

    //结束日期
    &_overDate {
      border: 1px solid #ccc;
      background: white;
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding: 2px;
      color: #ff9900;
    }

    //认证图标
    &_credenttial {
      background: white;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 2px;
      color: #ff9900;

      img {
        border: 1px solid #ccc;
      }
    }
  }
}
</style>

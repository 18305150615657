<template>
  <div class="allgoods_header">
    <div class="allgoods_header_first">
      <!-- {{ $t("All Merchandise") }} -->
      <div style="font-size: 18px">
        <i class="el-icon-s-home"></i> {{ $t("Home") }} > {{ NavTitle }}
      </div>
    </div>
    <div class="goods-type-tree">
      <div class="allgoods_header_second">
        <span style="font-size: 18px; font-weight: bold">{{ $t("Category") }}：</span>
        <span class="catp" v-bind:class="{ highlight: '-1' === tkey.one }"
              @click="catClick('-1')">{{ language === "zh-CN" ? "全部" : "All" }}</span>
        <span class="catp" v-bind:class="{ highlight: index === tkey.one }"
              v-for="(value,index) in goodsTypeTree" :key="index">
          <span @click="catClick(value.cls_no)">{{ language === "zh-CN" ? value.cls_cn : value.cls_name }}</span>
        </span>
      </div>
      <div class="allgoods_header_second" v-if="showSecond && goodsTypeTree[subIndex] !== undefined">
        <span style="font-size: 18px; font-weight: bold">
          {{ language === "zh-CN" ? secondList.cls_cn : secondList.cls_name }}：
        </span>
        <span class="catp" v-bind:class="{ highlight: isub === tkey.two }"
              v-for="(vsub,isub) in secondList.sub" :key="isub" >
          <span @click="catClick(vsub.cls_no)">{{ language === "zh-CN" ? vsub.cls_cn : vsub.cls_name }}</span>
        </span>
      </div>
      <div class="allgoods_header_second" v-if="showThree">
        <span style="font-size: 18px; font-weight: bold">{{ language === "zh-CN" ? threeList.cls_cn : threeList.cls_name
          }}：</span>
        <span class="catp" v-bind:class="{ highlight: isub === tkey.three }"
              v-for="(vsub,isub) in threeList.sub" :key="isub">
          <span @click="catClick(vsub.cls_no)">{{ language === "zh-CN" ? vsub.cls_cn : vsub.cls_name }}</span>
        </span>
      </div>
      <div class="allgoods_header_second" v-if="showFour">
        <span style="font-size: 18px; font-weight: bold">{{ language === "zh-CN" ? showFourList.cls_cn : showFourList.cls_name
          }}：</span>
        <span class="catp" v-bind:class="{ highlight: isub === tkey.four }"
              v-for="(vsub,isub) in showFourList.sub" :key="isub">
          <span @click="catClick(vsub.cls_no)">{{ language === "zh-CN" ? vsub.cls_cn : vsub.cls_name }}</span>
        </span>
      </div>
    </div>

    <div class="allgoods_header_second">
      <p style="font-size: 18px; font-weight: bold">{{ $t("Purchase Price") }}：</p>
      <a style="margin: 0 15px 0 8px; font-size: 18px" v-for="(item, key) in priceList" :key="key"
         @click="selPrice(item)">{{ item.value }}</a>
    </div>
    <div class="allgoods_header_third">
      <p>{{ $t("Recommended") }}:</p>
    </div>
    <div class="allgoods_header_note" v-if="
         this.NavTitle === 'China Warehouse Products' ||
          this.NavTitle === '中国仓产品'">
      <p>{{ $t("Note:Chinese warehouse products are packaged in Chinese") }}</p>
    </div>

  </div>
</template>
<script>
import { getTreeType } from "@/api/goods/goodsType.js";

export default {
  data() {
    return {
      tkey: {
        one: "-1",
        two: "",
        three: "",
        four:""
      },
      typeList: [],
      priceList: [
        {
          key: "all",
          value: this.$t('All'),
        },
        {
          key: "1",
          value: "0-10",
        },
        {
          key: "2",
          value: "11-20",
        },
        {
          key: "3",
          value: "21-40",
        },
        {
          key: "4",
          value: "41-60",
        },
        {
          key: "5",
          value: this.$t('61 & Above'),
        },
      ],
      language: "",
      NavTitle: "Products",
      showSecond: false,
      secondList: {},
      showThree: false,
      threeList: {},
      goodsTypeTree: {},
      showFour:false,
      showFourList:{},
      subIndex: "",
      threeIndex: "",
      nowType: 0,
    };
  },
  watch: {
    "tkey.one"(val) {
      //一级分类，判断是否存在下属，若存在则二级展示
      if (val === "-1") {
        this.showSecond=false;
        this.showThree=false;
        this.showFour= false;
      }
      if (val !== "-1" && this.goodsTypeTree[val] !== undefined) {
        this.showSecond=true;
        this.secondList=this.goodsTypeTree[val];
      }
    },
    "tkey.two"(val) {

      //二级分类，是否存在下属，若存在则三级展示
      if (val === "-1") {
        this.showThree=false;
      }

      if (val !== "-1" &&
        this.goodsTypeTree[this.tkey.one] !== undefined &&
        this.goodsTypeTree[this.tkey.one].sub[val] !== undefined) {
        this.showThree=true;
        this.threeList=this.goodsTypeTree[this.tkey.one].sub[val];
      }
    },
    "tkey.three"(val) {
      //三级分类，是否存在下属，若存在则四级展示
      if (val === "-1") {
        this.showFour=false;
      }

      if (val !== "-1" &&
        this.goodsTypeTree[this.tkey.one] !== undefined &&
        this.goodsTypeTree[this.tkey.one].sub[this.tkey.two] !== undefined && 
        this.goodsTypeTree[this.tkey.one].sub[this.tkey.two].sub[val] !== undefined)
        {
        this.showFour=true;
        this.showFourList=this.goodsTypeTree[this.tkey.one].sub[this.tkey.two].sub[val];
      }
    },

  },
  async created() {
    await this.getType();
    this.getLang();
    this.setTitle();

    const cls_no=this.$route.query && this.$route.query.cls_no;

    cls_no !== undefined && this.initClick(cls_no);

  },
  methods: {
    //设置标题
    setTitle() {
      if (window.location.href.indexOf("/web/allGoods") > 0) {
        this.NavTitle=this.$t("All Merchandise");
      }
      if (window.location.href.indexOf("/web/onePiece") > 0) {
        this.NavTitle=this.$t("MOQ is 1piece");
      }
      if (window.location.href.indexOf("/web/newProduct") > 0) {
        this.NavTitle=this.$t("New Arrivals");
        // this.showSecond=false;
        // this.showThree=false;
      }
      if (window.location.href.indexOf("/web/warehouseProducts") > 0) {
        this.NavTitle=this.$t("China Warehouse Products");
      }
    },
    //获取语言
    getLang() {
      this.language=this.$i18n.locale;
    },
    //点击价格
    selPrice(row) {
      this.$emit("selPrice", row);
    },
    //点击类型
    catClick(value) {
      const str=value === "-1" ? "-1" : value + "";
      this.tkey.one=str.length >= 2 ? str.slice(0, 2) : "-1";
      this.tkey.two="-1";
      this.tkey.three="-1";
      this.tkey.four = "-1"

      // if (this.tkey.one !== "-1" && this.NavTitle !== "New Arrivals") {
      if (this.tkey.one !== "-1" ) {
        //不是新品，则设置二，三级分类
        this.subIndex=this.tkey.one;
        this.tkey.two=str.length >= 4 ? str.slice(0, 4) : "-1";
        this.tkey.three=str.length >= 6 ? str.slice(0, 6) : "-1";
        this.tkey.four=str.length >= 8 ? str.slice(0, 8) : "-1";
      }
      const newQuery=JSON.parse(JSON.stringify(this.$route.query));

      if (newQuery.cls_no !== undefined) {
        // delete newQuery.cls_no;
        // this.$router.replace({ query: newQuery });
      }

      this.$emit("selCat", { cls_no:value });
    },

    //获取类型
    async getType() {

      const rec=await getTreeType();
      this.goodsTypeTree=rec.data.data;

    },
    initClick(cls_no) {
      this.$nextTick(() => {
        this.catClick(cls_no);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.catp {
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
}

// .catp:hover {
// 	background: #238da5;
// 	color: white;
// }

.highlight {
  background: #238da5;
  color: white;
}

.allgoods_header_second a {
  padding: 5px;
}

a:hover {
  background: #238da5;
  color: white !important;
}

.lastP {
  border: none;
}

.allgoods_header {
  width: 100%;
  // height: 190px;
  margin-top: 20px;

  &_first {
    width: 1540px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    color: #515a6e;
  }

  &_second {
    width: 1540px;
    // height: 45px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f8fa;
    color: #515a6e;
    font-size: 15px;
    flex-wrap: wrap;
  }

  &_third {
    width: 1540px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #515a6e;
    font-size: 18px;
  }

  &_note {
    width: 1540px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    p {
      font-weight: bold;
      color: #ed4014;
      font-size: 28px;
    }
  }
}
</style>
